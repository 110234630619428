import React, { useEffect, useState } from 'react';
import {
    Container, Row, Col
} from 'react-bootstrap';
import { Header } from '../../layout/header';
import './payment.scss';
import { getAuthUser } from "../../../auth";
import Select from 'react-select';
import Status_board from '../../layout/Status_board';
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios';
import { Add_passenger_info } from '../../../redux/actions/PassengerInfostateActions';
import { Link, useNavigate } from 'react-router-dom';
import { select_booking, save_booking } from '../../../redux/actions/BookingstateActions';
import LoadingShow from '../../admin/components/LoadingShow';
import { CreditCard, PaymentForm } from 'react-square-web-payments-sdk';
import moment from "moment";

const weekday = ["Sun", "Mon", "Tues", "Wed", "Thur", "Fri", "Sat"];
const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

const getStartDate = (dateinput) => {
    let date = new Date(dateinput) 
    let day = date.getDay();
    day = weekday[day];
    let month = date.getMonth();
    month = monthNames[month];
    const exactdate = date.getDate();
     const year = date.getFullYear();
    const startDate = day + ", " + month + ", " + exactdate + ", " + year;
    return startDate
};

const Payment = () => {
    const authUser = getAuthUser();
    const [passengerInfoError, setPassengerInfoError] = useState(false);
    const [cardPaymentError, setCardPaymentError] = useState(false);
    const temp = useSelector(state => state.bookingState.temp_booking);
    console.log(temp);
    if(!temp || !temp.pickup_date) {
        window.location.href = window.location.origin+"/home";
    }
    const [firstName, setFirstName] = useState(authUser?.name??"");
    const [lastName, setLastName] = useState();
    const [mobileNum, setMobileNum] = useState();
    const [email, setEmail] = useState(authUser?.email??"");
    const [contractName, setContractName] = useState();
    const [aliasSign, setAliasSign] = useState();
    const [cardNumber, setCardNumber] = useState();
    const [copyCardNumber, setCopyCardNumber] = useState('');
    const [year, setYear] = useState();
    const [month, setMonth] = useState();
    const [bookingSaveId, setBookingSaveId] = useState(null);
    const [cardHolder, setCardHolder] = useState();
    const [instruction, setInstruction] = useState();
    const [postalCode, setPostalCode] = useState();
    const [time, setTime] = useState(temp.pickup_time)
  //  const [startDate, setStartDate] = useState(temp.pickup_date?.format("ddd, MMM DD, YYYY"));
  const [startDate, setStartDate] = useState( getStartDate(temp.pickup_date));
    const [paymentMethod, setPaymentMethod] = useState();
    const [cvv, setCvv] = useState();
    const [loadingShow, setLoadingShow] = useState(false);
    const options = [
        { label: 'Cash', value: 1 },
        { label: 'credit card', value: 2 }
    ]
    const dispatch = useDispatch();
    const clickPayment = async () => {
        if(!firstName || !lastName || !mobileNum || !email || !aliasSign) {
            setPassengerInfoError(true);
            window.scrollTo({top: 0});
            return;
        } else {
            setPassengerInfoError(false);
        }
        if(paymentMethod == 2 && (!paymentMethod || !copyCardNumber || !month || !year || !cardHolder)) {
            window.scrollTo({top: 500});
            setCardPaymentError(true);
            return;
        } else {
            setCardPaymentError(false);
        }
        console.log('abc');
        let temp_passenger = {}
        temp_passenger.first_name = firstName;
        temp_passenger.last_name = lastName;
        temp_passenger.mobile_phone = mobileNum;
        temp_passenger.email = email;
        temp_passenger.contact_name = aliasSign;
        temp_passenger.alias_namesign = cardNumber;
        setLoadingShow(true);
        // update user profile
        if(!authUser || (authUser && authUser.name == "")) {
        let data = {}
        data.first_name = firstName
        data.email = email
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/update`,data)
        .then((res)=>{
        })
        }
                // end update profile
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/passengerInfo/add/`, temp_passenger)
            .then((res) => {
                if (res.data && res.data.status === 'ok') {
                    temp.user_info_id = res.data.id
                    temp.pickup_location = temp.pick_location;
                    postToBooking(res.data.id);
                }
            })
        dispatch(Add_passenger_info(temp_passenger));
    }
    const postToBooking = (id) => {
        temp.special_instruction = instruction;
        temp.estimate_price = temp.vehicle
        temp.booking_status = 2;
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/booking/create`, temp)
            .then((res) => {
                setBookingSaveId(res.data.id)
                postToPassInfo(res.data.id, id)
                postTostop(res.data.id)
            })
    }

    const postToPassInfo = (id, passenger_id) => {
        const passengertemp = {};
        passengertemp.passenger_id = passenger_id;
        passengertemp.booking_id = id;
        passengertemp.payment_method_id = paymentMethod;
        passengertemp.card_number = copyCardNumber;
        passengertemp.month = month;
        passengertemp.year = year;
        passengertemp.card_holder = cardHolder;
        passengertemp.postal_code = postalCode;
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/booking/paymentCreate`, passengertemp)
    }

    const getTimeString = (time) => {
        var date = moment(time, 'hh:mm A');
        var timeStringFormatted = date.format('HH:mm A'); // 'HH:mm' displays hours in 24-hour format, 'hh:mm' for 12-hour format
        return timeStringFormatted;

    }

    const mobileNumInputChange = (e) => {
        var x = e.target.value.replace(/\D/g, '').match(/(\d{3})(\d{3})(\d{4})/);
        if (x) {
            var v = x[1] + '-' + x[2] + '-' + x[3];
            setMobileNum(v)
        }
        else {
            setMobileNum(e.target.value)
        }
    }
    const updateCardNumber = (e) => {
        let keyCode = e.keyCode;
        var val = copyCardNumber;
        var iVal = e.target.value;
        var selStart = e.target.selectionStart;

        switch (keyCode) {
            case 8: // backspace
                if (selStart > 0) {
                    val = val.substr(0, selStart - 1) + val.substr(selStart, val.length);
                }
                break;
            case 46: // del
                if (selStart < (val.length - 1)) {
                    val = val.substr(0, selStart) + val.substr(selStart + 1, val.length);
                }
                break;
            default:
                if (e.key.length > 1) break;
                if (val.length == 0) {
                    val = e.key;

                } else {
                    val = val.substr(0, selStart) + e.key + val.substr(selStart, val.length);
                }
                break;
        }
        setCopyCardNumber(val);
        var reg = /\d(?=\d{4})/mg
        val.replace(reg, "*")
        setCardNumber(val.replace(reg, "*"))
    }

    const postTostop = (id) => {
        temp.booking_id = id;
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/booking/stopsave`, temp)
            .then((res) => {
                if (res.data.status === 'ok')
                    postToNotification();
            })
    }
    let navigate = useNavigate();
    const postToNotification = () => {
        let tmp_notification = {
            data: 'Booking requested',
            is_read: 0,
            notification_type: 3

        }
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/notification/create`, tmp_notification)
            .then((res) => {
                setLoadingShow(false);
                naviageToHistory()
            })
    }
    const naviageToHistory = () => {
        let temp_booking = {}
        dispatch(save_booking(temp_booking))
        navigate('/history')
    }

    const createPayment = async (token) => {
        const body = {
            locationId: 'LT8VQWS9XAJY7',
            sourceId: token.token,
        };
        const paymentResponse = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/payment/`, body)
        if (paymentResponse.ok) {
            return paymentResponse.json();
        }
        else{
            const errorBody = await paymentResponse.text();
            throw new Error(errorBody);
        }
    }

    return (
        <>
            <div className='client-payment' style={loadingShow ? { display: 'none' } : {}}>
                <Header />
                <div className="main">
                    <Container>
                        <Status_board />
                        <div className='main-wrap payment-board'>
                            <div className='passenger-control-btn'>

                                <div className='passenger-control'>
                                    <div className='header-text'>Passenger Info</div>
                                    {passengerInfoError?<span className="text-danger">Please fill all the fields</span>:''}
                                    <div className='custom-row'>
                                        <div className='col-sm-6 col-xs-12 mb-3 input-wrapper'>
                                            <h5>First Name: </h5>
                                            <input type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                                        </div>
                                        <div className='col-sm-6 mb-3 input-wrapper'>
                                            <h5>Last Name: </h5>
                                            <input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className='custom-row'>
                                        <div className='col-sm-6 mb-3 input-wrapper'>
                                            <h5>Mobile Num: </h5>
                                            <input type="text" value={mobileNum} onChange={mobileNumInputChange} />
                                        </div>
                                        <div className='col-sm-6 mb-3 input-wrapper'>
                                            <h5>Email: </h5>
                                            <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className='custom-row'>
                                        <div className='col-sm-6 mb-3 input-wrapper'>
                                            <h5>Contract Name: </h5>
                                            <input type="text" value={contractName} onChange={(e) => setContractName(e.target.value)} />
                                        </div>
                                        <div className='col-sm-6 mb-3 input-wrapper'>
                                            <h5>Alias/Name sign:</h5>
                                            <input type="text" value={aliasSign} onChange={(e) => setAliasSign(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className='header-text payment-options-heading'>Payment Option</div>
                                    {paymentMethod == 2 && cardPaymentError?<span className="text-danger">Please make a Payment first.</span>:''}
                                    <div className='custom-row half-size payment-options'>
                                        <div className='vehicle-type'>
                                            <label className='title'>Payment Method:</label>
                                            <Select

                                                onChange={(e) => setPaymentMethod(e.value)}
                                                options={options}
                                                isSearchable="true"
                                            />
                                        </div>
                                    </div>
                                    {paymentMethod == 2?
                                    <PaymentForm
                                        applicationId={process.env.SQUARE_SANDBOX_KEY}
                                        cardTokenizeResponseReceived={async (token, verifiedBuyer) => {
                                            try {
                                                const paymentResults = await createPayment(token)
                                            }
                                            catch (e) {
                                                console.error(e.message);
                                            }
                                        }}
                                        locationId='LT8VQWS9XAJY7'>
                                        <CreditCard 
                                         buttonProps={{
                                            css: {
                                              backgroundColor: "#771520",
                                              fontSize: "14px",
                                              color: "#fff",
                                              "&:hover": {
                                                backgroundColor: "#530f16",
                                              },
                                            },
                                          }}
                                        />
                                    </PaymentForm>
                                    :''}
                                    <div className='header-text special-instructions'>Special Instructions</div>
                                    <Row>
                                        <Col md={12}>
                                            <div className='input-wrapper instruction'>
                                                <h5>Instructions: </h5>
                                                <div>
                                                    <textarea type="text" id="instruction" cols="4" rows="4" value={instruction} onChange={(e) => setInstruction(e.target.value)} />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>

                            <div className='journey-vehicle'>
                                <div className='journey'>
                                    <div className='header-wrap d-flex justify-content-between'>
                                        <label className='header-text'>Journey</label>
                                        <div style={{ display: 'inline-flex' }} className="date-time-wrapper">
                                            <div className='date'>
                                                <img src="/images/calendar.svg" alt="calender" width="20px" height="20px" />
                                                <p>{startDate}</p>
                                            </div>
                                            <div className='time'>
                                                <img src="/images/clock.svg" alt="clock" width="20px" height="20px" />
                                                <p style={{ fontSize: '16px' }}>{temp.pickup_time ? getTimeString(time) : ''}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='journey-information'>
                                        <div className='pickup-wrapper'>
                                            <div className='pickup'>
                                                <label className='title'>Pickup:</label>
                                                <p>{temp.pick_location}</p>
                                            </div>
                                        </div>
                                        {temp.stop ? temp.stop.map((val, key) => (
                                            <div className='pickup-wrapper'>
                                                <div className='stop d-flex align-items-center justify-content-between'>
                                                    <div className='stop-location w-100'>
                                                        <label className='title'>Stop{key}:</label>
                                                        <p>{val}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                        ) : ''}
                                        <div className='pickup-wrapper'>
                                            <div className='dropoff'>
                                                <label className='title'>Dropoff:</label>
                                                <p>{temp.dropoff_location}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='passenger-board'>
                                        <div className='passenger'>
                                            <div className='label'>Passenger</div>
                                            <div className='count'>{temp.passenger}</div>
                                        </div>
                                        <div className='childrens'>
                                            <div className='label'>Childrens</div>
                                            <div className='count'>{temp.children}</div>
                                        </div>
                                        <div className='bags'>
                                            <div className='label'>Bags</div>
                                            <div className='count'>{temp.bags}</div>
                                        </div>
                                    </div>
                                    <div className='vehicle'>
                                        <div className='label'>Vehicle</div>
                                        <div className='name'>{temp.vehicle ? temp.vehicle.name : ''}</div>
                                    </div>
                                    <div className='custom-row passenger-info'>
                                        <div className='vehicle'>
                                            <div className='label'>Passenger Name</div>
                                            <div className='name'>{firstName ? firstName : ''} {' ' + lastName ? lastName : ''}</div>
                                        </div>
                                        <div className='vehicle'>
                                            <div className='label'>Mobile Number</div>
                                            <div className='name'>{mobileNum}</div>
                                        </div>
                                    </div>
                                    <div className='custom-row passenger-info'>
                                        <div className='vehicle'>
                                            <div className='label'>Payment Option</div>
                                            <div className='name'>{paymentMethod === 1 ? 'Cash' : ''}{paymentMethod === 2 ? 'credit card' : ''}</div>
                                        </div>
                                        {paymentMethod === 2 ?
                                        <div className='vehicle'>
                                            <div className='label'>Card Number</div>
                                            <div className='name'>{cardNumber}</div>
                                        </div>:''
                                        }
                                    </div>
                                    <div className='dash-line'>
                                        <img src="/images/Line 109.png" width="100%" alt='dashed line' />
                                    </div>
                                    <div className='estimated-fair'>
                                        <div className='label'>Estimated Fair</div>
                                        <div className='cost'>${temp.vehicle ? temp.vehicle.rate : ''}</div>
                                    </div>

                                </div>
                                <div className='btns'>
                                    <div className="back"><Link to='/vehicles'>Go Back</Link></div>
                                    <div className="payment" id="sq-creditcard" onClick={() => clickPayment()}><span >Book your ride</span></div>
                                    <div className='termsConditions'> <Link to='//notification'>By booking, I agree to all the Trems & Conditions</Link></div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
            </div>
            <LoadingShow show={loadingShow}></LoadingShow>
        </>
    )
};

export { Payment };