import React, {
    useState,
    createContext,
    useMemo,
    useRef,
    useEffect,
} from "react";
import { Header } from '../../layout/header';
import { Container, Row, Col } from "react-bootstrap";
import "./Salereciept.scss";
import axios from 'axios';
export const UserContext = createContext();

const SaleReceipt = () => {
    const [reservationNumber, setReservationNumber] = useState("");
    const [lastdigitCC, setLastdigitCC] = useState("");
    const [lastName, setLastName] = useState("");
    const [downloadType, setDownloadType] = useState("");
    const [validationError, setValidationError] = useState(false);
    function downloadPDF() {
        if(reservationNumber == "" || lastdigitCC == "" || lastName ==  "" || downloadType == "") {
            setValidationError(true);
        } else {
            setValidationError(false);
        if(downloadType == "PDF") {
            generatePDFReceipt()
        } else {
        generateHTMLReceipt();
        }
        }
    }
    const generatePDFReceipt = () => {
        // Send a request to the backend to generate the PDF receipt
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/booking/generate-pdf-receipt`,{ reservationNumber: reservationNumber, lastdigitCC: lastdigitCC,lastName:lastName },{responseType: 'blob'})
        .then((response) => response.data)
          .then((blob) => {
            // const blob = new Blob([data], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'receipt.pdf';
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
          })
          .catch((error) => {
            console.error('Error generating PDF receipt: ', error);
          });
      };
    
      const generateHTMLReceipt = () => {
        // Send a request to the backend to generate the HTML receipt
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/booking/generate-html-receipt`, { reservationNumber: reservationNumber, lastdigitCC: lastdigitCC, lastName:lastName }, {responseType: 'blob'})
          .then((response) => {
            return response.data;
          })
          .then((htmlContent) => {
            // Create a Blob with the HTML content
            const blob = new Blob([htmlContent], { type: 'text/html' });
    
            // Create a URL for the blob and trigger a download link
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'receipt.html';
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
          })
          .catch((error) => {
            console.error('Error generating HTML receipt: ', error);
          });
      };

    return (
        <div className="sale-receipt">
            <Header />
            <div className="main">
                <Container>
                    <h2 className="mb-3">Quick Receipt</h2>
                    <Row>
                    <Col md={4}>
                        <div className="form-group">
                            <label for="reservation">*Reservation #</label>
                            <input id="reservation" type="text" value={reservationNumber} onChange={(e) => setReservationNumber(e.target.value)}/>
                        </div>
                    </Col>
                    <Col md={4}>
                    <div className="form-group">
                        <label for="cc" className="cc-label">*Last 4 Digits of CC	</label>
                        <input id="cc" type="number" value={lastdigitCC} onChange={(e) => setLastdigitCC(e.target.value)} />
                    </div>
                    </Col>
                    <Col md={4}>
                        <div className="form-group">
                        <label for="lastname">*Last Name</label>
                        <input id="lastname" type="text" value={lastName} onChange={(e) => setLastName(e.target.value)}/>
                        </div>
                    </Col>
                    </Row>
                    <div className="download-type">
                      <label className="receiptFormat">Receipt Format</label>
                       <input type="radio" id="pdf" onChange={(e) => setDownloadType(e.target.value)} name="downloadtype" value="PDF"/>
                       <label for="pdf">PDF</label>
                       <input type="radio" id="html" onChange={(e) => setDownloadType(e.target.value)} name="downloadtype" value="HTML"/>
                       <label for="html">HTML</label>
                    </div>
                    {validationError?<p className="text-danger">All Fields are Required.</p>:''}
                    <div className="btns">
                        <button className="ok" onClick={downloadPDF}>OK</button>
                        <button className="cancel">CANCEL</button>
                    </div>
                </Container>
            </div>
        </div>
    )
};
export { SaleReceipt };