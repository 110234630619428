/* global google */
import React from "react";
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  Marker,
  DirectionsRenderer
} from "react-google-maps";

class MapDirectionsRenderer extends React.Component {
  state = {
    directions: null,
    error: null
  };
  componentDidMount(){
    const { places, travelMode } = this.props;
    if (places?.length > 1)
    {
      const waypoints = places.map(p =>({
          location: {lat: p.latitude, lng:p.longitude},
          stopover: true
      }))
      const origin = waypoints.shift().location;
      const destination = waypoints.pop().location;
      const directionsService = new google.maps.DirectionsService();
      directionsService.route(
        {
          origin: origin,
          destination: destination,
          travelMode: travelMode,
          waypoints: waypoints
        },
        (result, status) => {
          if (status === google.maps.DirectionsStatus.OK) {
            this.setState({
              directions: result
            });
          } else {
            this.setState({ error: 'error' });
          }
        }
      );
    }
  }
  componentDidUpdate(prevProps){
    if (this.props.places !== prevProps.places) { 
      const { places, travelMode } = this.props;
      const waypoints = places.map(p =>({
          location: {lat: p.latitude, lng:p.longitude},
          stopover: true
      }))
      const origin = waypoints.shift().location;
      const destination = waypoints.pop().location;

      const directionsService = new google.maps.DirectionsService();
      directionsService.route(
        {
          origin: origin,
          destination: destination,
          travelMode: travelMode,
          waypoints: waypoints
        },
        (result, status) => {
          if (status === google.maps.DirectionsStatus.OK) {
            this.setState({
              directions: result
            });
          } else {
            this.setState({ error: 'error' });
          }
        }
      );
    }
  }
  render() {
    if (this.state.error) {
      return <h1>error</h1>;
    }
    return (this.state.directions && <DirectionsRenderer directions={this.state.directions} />)
  }
}

const Map = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap
      defaultCenter={props.defaultCenter}
      defaultZoom={props.defaultZoom}
    >
      {props.markers.map((marker, index) => {
        const position = { lat: marker.latitude, lng: marker.longitude };
        return <Marker key={index} position={position} />;
      })}
      <MapDirectionsRenderer
        places={props.markers}
        travelMode={google.maps.TravelMode.DRIVING}
      />
    </GoogleMap>
  ))
);

export default Map;
