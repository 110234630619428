import {useState,useEffect} from 'react'
// import Pusher from 'pusher-js';
import { useDispatch, useSelector } from 'react-redux'
import { add_badge } from '../../../redux/actions/NotificationstateActions';
import axios from 'axios';
const BellPic = () => {
  const message = useSelector(state => state.notificationState.newMessage);
  const dispatch = useDispatch();
  // const pusher = new Pusher("06cd02d44e7adc22e314", {
  //     cluster: "ap3"
  //   });
  //   const channel = pusher.subscribe("channel");
  //   channel.bind("event", (newMessage) => {
  //     //setMessages([...messages, newMessage]);
  //     dispatch(add_badge(true))
  //   });
    return(
          <img src={message?'/images/mark/active_notification.svg':'/images/mark/notification.svg'} alt='bell'  width="24px" height="24px" />
    )
}
export {BellPic}