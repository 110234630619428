import React, {useState,useEffect} from 'react';
import {
    Row,
    Col,
    CloseButton 
} from 'react-bootstrap';

import './dashboard.scss';
import './notification.scss';
import { Link } from 'react-router-dom';
import  Sidebar  from './sidebar'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios';
import UserIcon from '../components/userIcon'
import  {new_notification}  from '../../../redux/actions/NotificationstateActions';
import { BellPic } from '../components/bellPic';
import { DynamicTimer } from '../components/timer'
const Notification = () => {
    const [notifications, setNotifications] = useState([]);
    const dispatch = useDispatch()
    const message = useSelector(state => state.notificationState.newMessage); 
    const close_click= (val,id) =>{
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/notification/update`,{is_read:1,id:id})
        .then((res)=>{
            let tempArr = Array.from(notifications);
            tempArr.splice(tempArr.indexOf(val), 1);
            if (tempArr.length == 0) dispatch(new_notification(false))
            setNotifications(tempArr);
        })
        
    }
    useEffect(()=>{
        
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/notification/admin/get`)
        .then((res)=>{
            dispatch(new_notification(res.data));
            setNotifications(res.data)
        })
    },[])
    return (
        <div className='dashboard'>
            <Sidebar/>
            <div className='admin-notification content'>
                <div className='content-panel'>
                <div className='content-panel__heading'>
                        <div className='caption'>
                            <h5>dashboard overview</h5>
                            <DynamicTimer/>
                        </div>
                        <div className='dropdown'>
                            <div className='nav-item bell'>
                                <div className='svg-container'>
                                    <Link to="/admin/notification">
                                        <BellPic/>
                                    </Link>
                                </div>
                            </div>
                            <UserIcon></UserIcon>
                        </div>
                    </div>
                    <div className='content-panel__content'>    
                        <Row>
                            <Col xs={12}>
                                <div className="notification">
                                    <h4>Notifications</h4>
                                </div>
                            </Col>                         
                        </Row>
                        {
                                notifications.map((val, key) => {
                                return (
                                    <div className='notification-item' key={key}>
                                        <div className='notification-item__content'>
                                            <h5 className='noti-title'>
                                              {val.notification_types[0]?val.notification_types[0].notification_type:''}
                                            </h5>
                                            <h5 className='noti-content'>
                                                {val.data}
                                            </h5>
                                        </div>
                                        <div className='notification-item__close'>
                                            <CloseButton onClick={()=>close_click(val,val.id)}/>
                                        </div>
                                    </div>
                                )
                                })
                            } 
                    </div>
                </div>
            </div>
        </div>
    )    
};

export { Notification };