import React, {useState,useEffect} from 'react';
import {
    Row,
    Col,
    Tabs,
    Tab,
    Form,
    Modal,
    CloseButton
} from 'react-bootstrap';
import './dashboard.scss';
import axios from 'axios';
import './booking.scss'
import driver_img from "../../../assets/images/Ellipse 212.png"
import { Link } from 'react-router-dom';
import Sidebar from './sidebar.js'
import { select_booking } from '../../../redux/actions/BookingstateActions';
import { new_notification } from '../../../redux/actions/NotificationstateActions'
import CustomSelect from '../components/customSelect';
import Confirm_modal from '../modal/confirm_modal';
import Notification_modal from '../modal/notification_modal';
import { useDispatch, useSelector } from 'react-redux'
import UserIcon from '../components/userIcon'
import { BellPic } from '../components/bellPic';
import { DynamicTimer } from '../components/timer'
import LoadingShow from '../components/LoadingShow';
const Booking = () => {    
    const [searchKey,setSearchKey] = useState();    
    const [images,setImages] =  useState();
    const [confirmModalShow, setConfirmModalShow] = useState(false)
    const [loadingShow, setLoadingShow] = useState(false);
    const handleConfirmModalClose = () => setConfirmModalShow(false)
    const [modalshow, setModalshow] = useState(false);
    const [modaltitle, setModaltitle] = useState("add new vehicel");
    const handleModalShow = () => {setModalshow(true);setModaltitle('add new vehicle');}
    const handleModalClose = () => setModalshow(false);    
    const [key, setKey] = useState('booking');
    const [bookings, setBookings] = useState([]);
    const [options,setOptions] = useState([]); 
    const handleNotificationModalClose = () => setNotificationModalShow(false)
    const [ notificationModalShow, setNotificationModalShow ]=useState(false)

    const [ isAllchecked, setIsAllchecked ] = useState(false)//value of all checkboxes
    
    //set all check boxes are true or false
    const checkBoxClick = () => {
        setIsAllchecked(!isAllchecked)
        let temp_array = Array.from(bookings)
        temp_array.map((val)=>{
            val.active = !isAllchecked;
        })
        setBookings(temp_array)
    }

    const handleSearchChange = (e) => {
        setSearchKey(e.target.value)
    }
    const dispatch = useDispatch()
    const searchFilter = () => {
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/booking/get`)
        .then((res)=>{
            let data = res.data;
            dispatch(select_booking(res.data));
            
            let filtered;
            if (!searchKey)  
                setBookings(res.data)
            else
                {          
                    filtered = data.filter(entry => Object.values(entry).some(val =>  typeof val === "string" &&  val.includes(searchKey)));            
                    setBookings(filtered)
                }
        })
    }
    const initial_function = () => {
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/booking/get`)
        .then((res)=>{
            let data = res.data;
            
            dispatch(select_booking(res.data));
            let filtered;
            
            if (!searchKey)  
                setBookings(res.data)
            else
                {          
                    filtered = data.filter(entry => Object.values(entry).some(val =>  typeof val === "string" &&  val.includes(searchKey)));            
                    setBookings(filtered)
                }
        })
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/notification/get`)
        .then((res)=>{
            
            if (res.data.length > 0)
                dispatch(new_notification(true));
            
        })
    }
    useEffect(()=>{
        initial_function()
    },[])
    
    const [ buttonActive, setButtonactive]=useState(false);
    const [ deleteButtonActive, setDeleteButtonActive]=useState(false)
    const [ booking_status,setBooking_status] = useState([]);
    const [ price , setPrice ] = useState();
    const [ cancelStatus, setCancelStatus ] = useState([]);
    const [ cancelId, setCancelId ] = useState()
    const [modalName,setModalName] =useState();
    const [modalRate, setModalRate] = useState();
    const [modalPassenger,setModalPassenger] = useState();
    const [modalImageurl,setModalImageUrl]=useState([]);
    const [modalBag,setModalBag] = useState();
    const [modalId,setModalId] = useState()
    const postToNotification = (data) => {
        let tmp_notification = {
            data:data,
            is_read: 0,
            user_to_notify:0

        }
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/notification/create`,tmp_notification)
    }
    const cancel_booking = () => {
        setConfirmModalShow(false)
        setLoadingShow(true)  
        let tempArray = [];
        cancelId?.map(()=>tempArray.push(3))
        if (cancelId && buttonActive && cancelStatus)
            axios.post(`${process.env.REACT_APP_API_BASE_URL}/booking/update`,{id:cancelId,booking_status: tempArray})
            .then((res)=>{
                dispatch(select_booking(res.data));
                setBookings(res.data)
                setLoadingShow(false)
                postToNotification('Booking cancelled')
                setNotificationModalShow(true);
            })
    }
    const booking_delete = () => {
        let temp_array = [];
        bookings.map((val)=>{
            if (val.active)
                { 
                    temp_array.push(val.id)
                }
            if (temp_array.length > 0)
                setLoadingShow(true)  
                axios.post(`${process.env.REACT_APP_API_BASE_URL}/booking/delete`,{temp_array:temp_array})
                .then((res)=>{
                    initial_function();
                    postToNotification('Booking deleted');
                })
        })
    }
    const [ confirmModalContent, setConfirmModalContent] = useState('')
    const [ confirmModalTitle, setconfirmModalTitle] = useState('')
    const [ confirmButtonName, setConfirmButtonName ] = useState('')
    const updateVehicle =  async () => {
        const formData = new FormData();
        let temp_files = []
        setLoadingShow(true)
        if (images)
            images.map((image)=>{
                temp_files.push(image.file)
            })
        
        {temp_files.map(file=>{
            formData.append("uploadImages", file);
          });}
        let temp={};
        temp.name = modalName;
        temp.max_passenger = modalPassenger;
        temp.rate = modalRate;
        temp.max_bags=modalBag;
        temp.images = images;
        
        if (modalId)
        {   temp.id = modalId;
            const result = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/vehicle/upload`,formData, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                  'boundary':'${form_data._boundary}'
                },
              });
              temp.urls=[]
            if (modalImageurl)
                modalImageurl.map(val=>
                    temp.urls.push(val.id))
            if (result.data.data)
                result.data.data.map(val=>
                    temp.urls.push(val.id))
            
            axios.post(`${process.env.REACT_APP_API_BASE_URL}/vehicle/update`,temp)
            .then((res)=>{
                setModalshow(false)
                setLoadingShow(false)
            })
        }
        else{
            const result = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/vehicle/upload`,formData, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                  'boundary':'${form_data._boundary}'
                },
              });
            temp.urls = [...result.data.data]
           
            ;
            axios.post(`${process.env.REACT_APP_API_BASE_URL}/vehicle/create`,temp)
            .then((res)=>{
                setModalshow(false)
                setLoadingShow(false)
            })
        }
    }
    const updateBookingRecord = () => {    
          
        let id=[];let bookingStatuses=[];let estimate_price;let cancel_status;
            bookings.map((val,index)=>{
                if (val.active)
                    { 
                        id.push(val.id)
                        
                        bookingStatuses.push(booking_status[index])
                        estimate_price = val.estimate_price
                        cancel_status = val.cancel;
                }
                setCancelId(id)
            })
            
        switch (key){
            case 'booking_status':
                if (id && buttonActive){
                    setLoadingShow(true)  
                    
                    axios.post(`${process.env.REACT_APP_API_BASE_URL}/booking/update`,{id:id,booking_status: bookingStatuses})
                    .then((res)=>{
                        dispatch(select_booking(res.data));
                        setBookings(res.data)
                        setLoadingShow(false)
                        postToNotification('Booking Status Updated')
                    })}
                break
            case 'Cancel_booking':
                    setConfirmModalContent('"Do you want to cancel this ride ?"');
                    if (cancel_status)
                    {

                        setConfirmModalShow(true);
                        setconfirmModalTitle('Cancel the ride')
                        setConfirmButtonName('Cancel')

                    }
                break
            case 'driver':
                    setConfirmModalContent('"Do you want to Reassign this ride ?"');
                    setConfirmModalShow(true);
                    setconfirmModalTitle('Reassign the ride')
                    setConfirmButtonName('Reassign')
            case 'fix_price':
                if (id && buttonActive){
                    setLoadingShow(true)  
                    axios.post(`${process.env.REACT_APP_API_BASE_URL}/booking/update`,{id:id,estimate_price: estimate_price})
                    .then((res)=>{
                        dispatch(select_booking(res.data));
                        setBookings(res.data)
                        setLoadingShow(false)
                        postToNotification('Price has been fixed')
                    })}
                break
        }
    }
    useEffect(()=>{
        let temp_array = Array.from(bookings)
        let count = 0;
        temp_array.map((val)=>{
            if (val.active)
                count++
        })

        if (count > 0)
            setDeleteButtonActive(true)
        else
            setDeleteButtonActive(false)
        // if (count === 1)
        //     setButtonactive(true);
        // else 
        //     setButtonactive(false);
        
        //check if checkbox is selected
        if (count > 0)
            setButtonactive(true)
        else
            setButtonactive(false)


    },[bookings])
    return (
        <div className='dashboard'>
            <Sidebar/>
            <div className='content admin-booking'>
                <div className='content-panel'>
                    <div className='content-panel__heading'>
                        <div className='caption'>
                            <h5>booking</h5>
                            <DynamicTimer/>
                        </div>
                        <div className='dropdown'>
                            <div className='nav-item'>
                                <div className='search'>
                                    <input type="text" value={searchKey} onChange={handleSearchChange} placeholder="search.."/>
                                    <i onClick={searchFilter} className='fa fa-search' style={{cursor:'pointer'}}></i>
                                </div>
                            </div>
                            <div className='nav-item'>
                                <div className='svg-container'>
                                    <Link to="/admin/notification">
                                        <BellPic></BellPic>
                                    </Link>
                                </div>
                            </div>
                            <UserIcon></UserIcon>
                        </div>
                    </div>
                    <div className='content-panel__content'>
                        <Row style={{marginTop:'50px'}}>
                            <Col xs={12}>
                                <div className='card'>
                                    <div className='card-body'>
                                        <div className='btns'>
                                            <h6 className={buttonActive ? 'update active' : 'update'} onClick={updateBookingRecord}>
                                                update
                                                <i className="fas fa-chevron-down"></i>
                                            </h6>
                                            <h6 className={deleteButtonActive? 'delete active':'delete' } onClick={booking_delete}>delete</h6>
                                        </div>
                                        <Tabs
                                            id="controlled-tab-example"
                                            activeKey={key}
                                            onSelect={(k) => setKey(k)}
                                            className="mb-3 section-header"
                                            >
                                           
                                            <Tab eventKey="booking" title="Bookings">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th> <Form.Check.Input
                                                                    type={"checkbox"}
                                                                    checked={isAllchecked}
                                                                    onClick={checkBoxClick}
                                                                    
                                                                 />
                                                            </th>
                                                            <th>Trip Number</th>
                                                            <th>Pickups</th>
                                                            <th>Drop Off</th>
                                                            <th>Passenger Name</th>
                                                            <th>Passenger Number</th>
                                                            <th>Passengers</th>
                                                            <th>Date & time</th>
                                                            <th>Vehicle</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            bookings.map((val, key) => {

                                                        return (
                                                            <tr key={key}>
                                                                <td>
                                                                     <Form.Check
                                                                    type={"checkbox"}
                                                                    checked={val.active}
                                                                    onChange={(e) => {
                                                                        let temp_array = Array.from(bookings);
                                                                        e.target.checked?temp_array[key].active = true:temp_array[key].active = false;
                                                                        setBookings(temp_array);
                                                                    }}
                                                                />
                                                                </td>
                                                                <td>#{val.id}</td>
                                                                <td>{val.pickup_location}</td>
                                                                <td>{val.dropoff_location}</td>
                                                                <td>{val.passenger_infos?val.passenger_infos[0].first_name:''}</td>
                                                                <td>{val.passenger_infos?val.passenger_infos[0].id:'' }</td>
                                                                <td>{val.passenger}</td>
                                                                <td>{val.pickup_date} {val.pickup_time}</td>
                                                                <td>{val.vehicles?val.vehicles[0].name:''}</td>
                                                            </tr>
                                                        )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </Tab>
                                            <Tab eventKey="booking_status" title="Booking status">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                 <Form.Check.Input
                                                                    type={"checkbox"}
                                                                    checked={isAllchecked}
                                                                    onClick={checkBoxClick}
                                                                    
                                                                 />
                                                            </th>
                                                            <th>Trip Number</th>
                                                            <th>Pickups</th>
                                                            <th>Drop Off</th>
                                                            <th>Passenger Name</th>
                                                            <th>Passenger Number</th>
                                                            <th>Date & time</th>
                                                            <th>status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                   
                                                    bookings.map((val, key) => {
                                                        let temp_date = new Date(val.pickup_date);
                                                        let temp_date_string = '';
                                                        let temp_date_date = temp_date.getDate();
                                                        let temp_date_year = temp_date.getFullYear();
                                                        temp_date_string = temp_date_date + temp_date_year;

                                                    return (
                                                        <tr key={key}>
                                                            <td>
                                                                     <Form.Check
                                                                    type={"checkbox"}
                                                                    checked={val.active}
                                                                    onChange={(e) => {
                                                                        let temp_array = Array.from(bookings);
                                                                        e.target.checked?temp_array[key].active = true:temp_array[key].active = false;
                                                                        setBookings(temp_array);
                                                                    }}
                                                                />
                                                                </td>
                                                            <td>#{val.id}</td>
                                                            <td>{val.pickup_location}</td>
                                                            <td>{val.dropoff_location}</td>
                                                            <td>{val.passenger_infos[0].first_name}</td>
                                                            <td>{val.passenger_infos[0].id}</td>
                                                            <td>{val.pickup_date} {val.pickup_time}</td>
                                                            <td>
                                                                <CustomSelect options={options} value={val.booking_status} indexKey={key} bookingStatus={booking_status} function={setBooking_status}></CustomSelect>
                                                            </td>
                                                            
                                                        </tr>
                                                    )
                                                    })}
                                                    </tbody>
                                                </table>
                                            </Tab>
                                            <Tab eventKey="Cancel_booking" title="Cancel booking">
                                                <table>
                                                    <thead>
                                                        <tr> 
                                                            <th>
                                                                 <Form.Check.Input
                                                                    type={"checkbox"}
                                                                    checked={isAllchecked}
                                                                    onClick={checkBoxClick}
                                                                    
                                                                 />
                                                            </th>
                                                            <th>Trip Number</th>
                                                            <th>Pickups</th>
                                                            <th>Drop Off</th>
                                                            <th>Passenger Name</th>
                                                            <th>Passenger Number</th>
                                                            <th>Date & time</th>
                                                            <th>Cancel Ride</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                             bookings.map((val, key) => {
                                                        return (
                                                            <tr key={key}>
                                                                <td>
                                                                        <Form.Check
                                                                        type={"checkbox"}
                                                                        checked={val.active}
                                                                        onChange={(e) => {
                                                                            let temp_array = Array.from(bookings);
                                                                            e.target.checked?temp_array[key].active = true:temp_array[key].active = false;
                                                                            setBookings(temp_array);
                                                                        }}
                                                                    />
                                                                </td>
                                                                <td>#{val.id}</td>
                                                                <td>{val.pickup_location}</td>
                                                                <td>{val.dropoff_location}</td>
                                                                <td style={{textAlign:'center'}}>{val.passenger_infos[0].first_name}</td>
                                                                <td style={{textAlign:'center'}}>{val.passenger_infos[0].id}</td>
                                                               
                                                                <td>{val.pickup_date}<br/>
                                                                    {val.pickup_time}
                                                                </td>
                                                                <td className='cancel-status'>{val.booking_status != 3 ?
                                                                        <h6 className={cancelStatus[key] ? 'canceled':'cancelling'} 
                                                                        onClick={()=>{ 
                                                                            let temp_array = Array.from(cancelStatus);
                                                                            let temp_booking  = Array.from(bookings);
                                                                            
                                                                               if( cancelStatus[key])
                                                                                   {
                                                                                        temp_array[key]=false;temp_booking[key].cancel = false;
                                                                                   }
                                                                                else
                                                                                    {
                                                                                        temp_array[key]=true;temp_booking[key].cancel = true;
                                                                                    }
                                                                            setCancelStatus(temp_array)
                                                                            setBookings(temp_booking)
                                                                            }}
                                                                        > {cancelStatus[key] ? 'canceled Ride':'cancel ride'}
                                                                        </h6>
                                                                    :<h6 className='canceled'>cancelled ride</h6>}
                                                                </td>
                                                                
                                                            </tr>
                                                        )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </Tab>
                                            <Tab eventKey="fix_price" title="Fix booking price">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                 <Form.Check.Input
                                                                    type={"checkbox"}
                                                                    checked={isAllchecked}
                                                                    onClick={checkBoxClick}
                                                                    
                                                                 />
                                                            </th>
                                                            <th>Trip Number</th>
                                                            <th>Pickups</th>
                                                            <th>Drop Off</th>
                                                            <th>Passenger Name</th>
                                                            <th>Passenger Number</th>
                                                            <th>Date & time</th>
                                                            <th>Fix the price</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                        bookings.map((val, key) => {
                                                        return (
                                                            <tr key={key}>
                                                                <td>
                                                                        <Form.Check
                                                                        type={"checkbox"}
                                                                        checked={val.active}
                                                                        onChange={(e) => {
                                                                            let temp_array = Array.from(bookings);
                                                                            e.target.checked?temp_array[key].active = true:temp_array[key].active = false;
                                                                            setBookings(temp_array);
                                                                        }}
                                                                    />
                                                                </td>
                                                            <td>#{val.id}</td>
                                                            <td>{val.pickup_location}</td>
                                                            <td>{val.dropoff_location}</td>
                                                            <td>{val.passenger_infos[0].first_name}</td>
                                                            <td>{val.passenger_infos[0].id}</td>
                                                            <td>{val.pickup_date} {val.pickup_time}</td>
                                                            <td><div className='table-price'>
                                                                    <span>$</span>
                                                                    <input 
                                                                    value={val.estimate_price}
                                                                    onChange={(e)=>{
                                                                        let  temp_array =Array.from(bookings);
                                                                        temp_array[key].estimate_price = e.target.value;
                                                                        setBookings(temp_array)
                                                                    }}>

                                                                    </input>
                                                                </div>
                                                            </td>
                                                            
                                                        </tr>
                                                    )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </Tab>
                                            <Tab eventKey="driver" title="Driver">
                                            <table>
                                                    <tr>
                                                        <th>
                                                            <Form.Check.Input
                                                            type={"checkbox"}
                                                            checked={isAllchecked}
                                                            onClick={checkBoxClick}
                                                            
                                                            />
                                                        </th>
                                                        <th>Trip Number</th>
                                                        <th>Pickups</th>
                                                        <th>Drop Off</th>
                                                        <th>Passenger Name</th>
                                                        <th>Date & time</th>
                                                        <th>Driver</th>
                                                        <th></th>
                                                    </tr>
                                                    {
                                                    bookings.map((val, key) => {
                                                    return (
                                                        <tr key={key}>
                                                            <td>
                                                                     <Form.Check
                                                                    type={"checkbox"}
                                                                    checked={val.active}
                                                                    onChange={(e) => {
                                                                        let temp_array = Array.from(bookings);
                                                                        e.target.checked?temp_array[key].active = true:temp_array[key].active = false;
                                                                        setBookings(temp_array);
                                                                    }}
                                                                />
                                                            </td>
                                                            <td>#{val.id}</td>
                                                            <td>{val.pickup_location}</td>
                                                            <td>{val.dropoff_location}</td>
                                                            <td>{val.passenger_infos[0].first_name}</td>
                                                            <td>{val.pickup_date}<br/>
                                                                {val.pickup_time}
                                                            </td>
                                                            <td>
                                                                <div className='driver-meta'>
                                                                    <div className='img-container'>
                                                                        <img src={driver_img} alt="img"></img>
                                                                    </div>
                                                                    <h5>{val.passenger_infos[0].first_name} {val.passenger_infos[0].last_name}</h5>
                                                                      
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <h6 className='reassign'>reassign</h6> 
                                                            </td>
                                                            
                                                        </tr>
                                                    )
                                                    })}
                                                </table>
                                            </Tab>
                                            
                                        </Tabs>
                                    </div>
                                </div>
                            </Col>                         
                        </Row>
                    </div>
                </div>
            </div>
            <Confirm_modal classProp="modal" content={confirmModalContent} button_name={confirmButtonName} modalTitle={confirmModalTitle} delete_vehicle={cancel_booking} show={confirmModalShow} onHide={handleConfirmModalClose}>
            </Confirm_modal> 
            <Notification_modal content="Rider has been Cancelled Successfully" modalTitle="Rider Canceled" show={notificationModalShow} onHide={handleNotificationModalClose}></Notification_modal>                                 
            <LoadingShow show={loadingShow}></LoadingShow>
        </div>
    )    
};

export { Booking };